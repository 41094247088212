import React from 'react'
import { Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Override } from "@quarkly/components";
import { project_name } from '../../data';

import WhyImg from '../../assets/img/why-img.png'

function About() {
  return (
    <Section id='about' padding="160px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0">
        <Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
        <Box
            width="50%"
            flex-direction="column"
            md-width="100%"
            empty-min-width="64px"
            empty-border-style="solid"
            empty-border-color="LightGray"
            padding="16px 16px 16px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="space-between"
            empty-min-height="64px"
            empty-border-width="1px"
        >
            <Image
                position="static"
                lg-left="0px"
                md-width="100%"
                border-radius="10px"
                lg-max-height="366px"
                src={WhyImg}
                left="-140px"
                max-width="100%"
                object-fit="cover"
            />
        </Box>
        <Box
            justify-content="space-between"
            empty-min-height="64px"
            empty-border-style="solid"
            width="50%"
            padding="16px 16px 16px 16px"
            display="flex"
            md-width="100%"
            empty-min-width="64px"
            empty-border-width="1px"
            empty-border-color="LightGray"
            flex-direction="column"
            align-items="flex-start"
        >
            {/* <Box display="flex" margin="0px 0px 20px 0px" align-items="center" sm-width="100%">
                <Image width="24px" height="24px" src="https://uploads.quarkly.io/6123949e957019001e284458/images/icon-man.svg?v=2021-09-21T22:14:38.397Z" margin="0px 15px 0px 0px" />
                <Text
                    margin="0px 0px 0px 0px"
                    color="--primary"
                    font="--lead"
                    lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                    md-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
                >
                    Contact a doctor in a snap
                </Text>
            </Box> */}
            <Text
                width="85%"
                lg-width="100%"
                lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
                md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
                margin="0px 0px 16px 0px"
                font="--headline3"
                color="--indigo"
                sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
            >
                Why You Should Choose Us?
            </Text>
            <Text
                font="--base"
                opacity=".7"
                lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
                md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
                margin="0px 0px 25px 0px"
                color="--dark"
            >
                {project_name} is an advanced platform leveraging AI from top machine learning experts, supported by elite analysts and traders. It's designed for time efficiency and investment security, offering assured capital growth through AI's precision.
            </Text>
            <Link
                href="/create-account"
                sm-padding="15px 20px 15px 20px"
                hover-background="rgba(5, 165, 255, 0)"
                border-width="1px"
                border-style="solid"
                font="--lead"
                sm-margin="0px 22px 0px 0px"
                border-color="rgba(255, 255, 255, 0.3)"
                md-width="100%"
                md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                text-decoration-line="initial"
                color="--white"
                margin="0px 44px 0px 0px"
                hover-color="--primary"
                hover-border-color="--color-primary"
                md-text-align="center"
                background="--color-primary"
                padding="15px 30px 15px 30px"
                border-radius="10px"
                sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
            >
                Join Us
            </Link>
        </Box>
    </Section>
  )
}

export default About
