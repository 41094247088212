import React from 'react'
import { Image, Box, Section, Text, Hr} from "@quarkly/widgets";
import { Override } from "@quarkly/components";



function Reviews() {
  return (
    <Section id='reviews' padding="60px 0 100px 0" md-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
            empty-border-style="solid"
            empty-border-color="LightGray"
            justify-content="center"
            align-items="center"
            flex-direction="column"
            width="100%"
            empty-min-width="64px"
            empty-min-height="64px"
            empty-border-width="1px"
            display="flex"
        >
            <Text
                margin="0px 0px 38px 0px"
                font="--headline4"
                color="--indigo"
                md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
                lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
                text-align="center"
            >
                Latest User Reviews
            </Text>
            <Hr
                border-style="solid"
                margin="0px 0px 0px 0px"
                height="0px"
                lg-display="none"
                width="100%"
                border-color="rgba(66, 82, 207, 0.1)"
                border-width="2px 0 0 0"
            />
        </Box>
        <Box
            flex-direction="column"
            justify-content="flex-start"
            border-width="0px 1px 0px 0px"
            md-padding="32px 16px 16px 16px"
            width="25%"
            max-height="265px"
            display="flex"
            align-items="center"
            sm-justify-content="flex-start"
            lg-justify-content="flex-start"
            md-align-items="center"
            border-color="rgba(66, 82, 207, 0.1)"
            height="100%"
            md-width="50%"
            sm-margin="0px 0px 30px 0px"
            border-style="solid"
            lg-padding="32px 16px 16px 16px"
            lg-min-height="298px"
            md-justify-content="flex-start"
            sm-padding="32px 16px 16px 16px"
            margin="0px 0px 0px 0px"
            padding="16px 32px 16px 16px"
        >
            <Image
                object-fit="cover"
                border-radius="50%"
                object-position="0% 0%"
                width="80px"
                height="80px"
                margin="0px 0px 8px 0px"
                src="https://images.unsplash.com/photo-1594194610831-c3f92ec62180?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=228&q=80"
                srcSet="https://images.unsplash.com/photo-1594194610831-c3f92ec62180?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1594194610831-c3f92ec62180?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1594194610831-c3f92ec62180?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1594194610831-c3f92ec62180?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1594194610831-c3f92ec62180?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1594194610831-c3f92ec62180?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1594194610831-c3f92ec62180?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3200&q=80 3200w"
                sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
            />
            <Text margin="0px 0px 8px 0px" font="--lead" color="--indigo" sm-text-align="center">
                John B.
            </Text>
            <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1" lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif">
                Advanced features and algorithms excel in high volatility, optimizing opportunities.
            </Text>
        </Box>
        <Box
            padding="16px 32px 16px 32px"
            border-width="0px 1px 0px 1px"
            width="25%"
            md-align-items="center"
            align-items="center"
            flex-direction="column"
            border-style="solid"
            border-color="rgba(66, 82, 207, 0.1)"
            lg-padding="32px 16px 16px 16px"
            lg-min-height="298px"
            md-border-width="0px 0px 0px 1px"
            md-justify-content="flex-start"
            sm-justify-content="flex-start"
            md-padding="32px 16px 16px 16px"
            max-height="265px"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            sm-margin="0px 0px 30px 0px"
            height="100%"
            lg-justify-content="flex-start"
            sm-padding="32px 16px 16px 16px"
            md-width="50%"
        >
            <Image
                object-position="0% 0%"
                width="80px"
                height="80px"
                margin="0px 0px 8px 0px"
                src="https://images.unsplash.com/photo-1552452518-f921c926b76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=272&q=80"
                object-fit="cover"
                border-radius="50%"
                srcSet="https://images.unsplash.com/photo-1552452518-f921c926b76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1552452518-f921c926b76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1552452518-f921c926b76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1552452518-f921c926b76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1552452518-f921c926b76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1552452518-f921c926b76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1552452518-f921c926b76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3200&q=80 3200w"
                sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
            />
            <Text font="--lead" color="--indigo" sm-text-align="center" margin="0px 0px 8px 0px">
                Bob W.
            </Text>
            <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1" lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif">
                AI-driven real-time data and analytics have maximized my returns.
            </Text>
        </Box>
        <Hr
            display="none"
            width="100%"
            border-color="rgba(66, 82, 207, 0.1)"
            border-style="solid"
            margin="0px 16px 32px 16px"
            md-margin="16px 16px 16px 16px"
            md-display="block"
            border-width="2px 0 0 0"
            height="0px"
        />
        <Box
            border-color="rgba(66, 82, 207, 0.1)"
            md-justify-content="flex-start"
            sm-margin="0px 0px 30px 0px"
            padding="16px 32px 16px 32px"
            border-style="solid"
            lg-padding="32px 16px 16px 16px"
            sm-padding="32px 16px 16px 16px"
            border-width="0px 1px 0px 1px"
            lg-min-height="298px"
            md-border-width="0px 1px 0px 0px"
            display="flex"
            align-items="center"
            flex-direction="column"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            height="100%"
            width="25%"
            md-width="50%"
            lg-justify-content="flex-start"
            md-align-items="center"
            sm-justify-content="flex-start"
            md-padding="32px 16px 16px 16px"
            max-height="265px"
        >
            <Image
                src="https://images.unsplash.com/photo-1563306406-e66174fa3787?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=274&q=80"
                object-fit="cover"
                border-radius="50%"
                width="80px"
                height="80px"
                margin="0px 0px 8px 0px"
                srcSet="https://images.unsplash.com/photo-1563306406-e66174fa3787?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1563306406-e66174fa3787?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1563306406-e66174fa3787?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1563306406-e66174fa3787?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1563306406-e66174fa3787?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1563306406-e66174fa3787?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1563306406-e66174fa3787?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3200&q=80 3200w"
                sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
            />
            <Text margin="0px 0px 8px 0px" font="--lead" color="--indigo" sm-text-align="center">
                Natalie J.
            </Text>
            <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1" lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif">
                Intuitive platform with AI insights that enhance my strategy and robust security.
            </Text>
        </Box>
        <Box
            align-items="center"
            padding="16px 32px 16px 32px"
            border-width="0px 0px 0px 1px"
            lg-padding="32px 16px 16px 16px"
            lg-min-height="298px"
            height="100%"
            md-align-items="center"
            width="25%"
            max-height="265px"
            md-width="50%"
            margin="0px 0px 0px 0px"
            border-color="rgba(66, 82, 207, 0.1)"
            lg-justify-content="flex-start"
            sm-padding="32px 16px 16px 16px"
            display="flex"
            sm-justify-content="flex-start"
            flex-direction="column"
            sm-margin="0px 0px 30px 0px"
            border-style="solid"
            md-justify-content="flex-start"
            md-padding="32px 16px 16px 16px"
        >
            <Image
                width="80px"
                height="80px"
                margin="0px 0px 8px 0px"
                src="https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80"
                object-fit="cover"
                border-radius="50%"
                srcSet="https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1604004555489-723a93d6ce74?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3200&q=80 3200w"
                sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
            />
            <Text margin="0px 0px 8px 0px" font="--lead" color="--indigo" sm-text-align="center">
                Laura H.
            </Text>
            <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1" lg-font="400 14px/1.6 &quot;Inter&quot;, sans-serif">
                Ideal for beginners with excellent resources and support, leading to portfolio growth.
            </Text>
        </Box>
    </Section>
  )
}

export default Reviews
