const to_usd_amount = (usdt) => {
    return usdt.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })
  }

function RoundNumber(num, precision=2) {
  let numStr = num.toFixed(20).toString();
  let dotIndex = numStr.indexOf('.');
  if (dotIndex !== -1) {
    numStr = numStr.substring(0, dotIndex + precision + 1);
  }
  return parseFloat(numStr);
}

function calculateTotalBalanceFree(userBalances, prices) {
  return userBalances.reduce((sum, account) => {
    const price = prices[account.token.gecko_name]['usd'];
    return sum + account.balance_free * price;
  }, 0);
}

export {to_usd_amount, RoundNumber, calculateTotalBalanceFree}