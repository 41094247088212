import React, { useRef } from 'react'
import { Link, Image, Box, Section } from "@quarkly/widgets";
import { Override } from "@quarkly/components";

import Logo from '../../assets/img/logo.png'

function Header() {
    const isAuth = window.localStorage.getItem('token')?true:false
  return (
    <Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="14px 0 14px 0" quarkly-title="Header">
        <Override slot="SectionContent" flex-direction="row" />
        <Box
            empty-border-width="1px"
            empty-border-style="solid"
            width="30%"
            sm-width="50%"
            display="flex"
            align-items="center"
            empty-min-width="64px"
            empty-min-height="64px"
            empty-border-color="LightGray"
        >
            <Image height="auto" padding-top='6px' src={Logo} width="100%" max-width="171px" />
        </Box>
        <Box
            empty-min-height="64px"
            empty-border-width="1px"
            width="70%"
            sm-width="50%"
            align-items="center"
            empty-min-width="64px"
            empty-border-style="solid"
            empty-border-color="LightGray"
            display="flex"
            justify-content='flex-end'
        >
            <Box
                display="flex"
                align-items="center"
                justify-content="flex-end"
                md-flex-direction="column"
                md-margin="40px 0px 13px 0px"
                md-font="--headline4"
                md-display='none'
            >
                <Link
                                href="#about"
                                font="normal 600 18px/1.5 --fontFamily-googleInter"
                                text-decoration-line="initial"
                                color="--white"
                                margin="0px 20px 0px 20px"
                                md-margin="0px 0px 13px 0px"
                                md-font="--lead"
                                md-color="--indigo"
                            >
                                About
                </Link>
                <Link
                    color="--grey"
                    margin="0px 20px 0px 20px"
                    transition="opacity 0.3s ease 0s"
                    md-margin="0px 0px 13px 0px"
                    md-font="--lead"
                    href="#earn"
                    font="--base"
                    text-decoration-line="initial"
                    opacity="0.8"
                    hover-opacity="1"
                    md-color="--indigo"
                >
                        Earn
                </Link>
                <Link
                        color="--grey"
                        margin="0px 20px 0px 20px"
                        transition="opacity 0.3s ease 0s"
                        md-margin="0px 0px 13px 0px"
                        md-font="--lead"
                        href="#stats"
                        font="--base"
                        text-decoration-line="initial"
                        opacity="0.8"
                        hover-opacity="1"
                        md-color="--indigo"
                    >
                        Stats
                </Link>
                <Link
                        hover-opacity="1"
                        md-font="--lead"
                        md-color="--indigo"
                        font="--base"
                        color="--grey"
                        opacity="0.8"
                        transition="opacity 0.3s ease 0s"
                        md-margin="0px 0px 13px 0px"
                        href="#reviews"
                        text-decoration-line="initial"
                        margin="0px 8px 0px 20px"
                    >
                        Reviews
                </Link>
                <Link
                                    hover-opacity="1"
                                    md-font="--lead"
                                    md-color="--indigo"
                                    font="--base"
                                    color="--grey"
                                    opacity="0.8"
                                    transition="opacity 0.3s ease 0s"
                                    md-margin="0px 0px 13px 0px"
                                    href="#features"
                                    text-decoration-line="initial"
                                    margin="0px 8px 0px 20px"
                                >
                                    Features
                </Link>                 
                <Link
                    hover-opacity="1"
                    md-font="--lead" 
                    font="--base"
                    md-color="--indigo"
                    color="--grey"
                    opacity="0.8"
                    transition="opacity 0.3s ease 0s"
                    md-margin="0px 0px 13px 0px"
                    href="/login"
                    text-decoration-line="initial"
                    margin="0px 8px 0px 20px"
                    border-radius="8px"
                    border='1px solid #505e7e87'
                    padding='6px 8px'
                    white-space='nowrap'
                >
                    Sign In
                </Link>
                <Link
                    hover-opacity="1"
                    md-font="--lead" 
                    font="--base"
                    opacity="0.8"
                    transition="opacity 0.3s ease 0s"
                    md-margin="0px 0px 13px 0px"
                    href="/create-account"
                    text-decoration-line="initial"
                    margin="0px 8px 0px 20px"
                    border-radius="8px"
                    border='1px solid #505e7e'
                    padding='6px 8px'
                    background='#05a5ff'
                    color='#ffffff'
                    white-space='nowrap'
                >
                    Sign Up
                </Link>
            </Box>
            <Box
                align-items="center"
                justify-content="flex-end"
                md-flex-direction="column"
                md-margin="15px 0px 13px 0px"
                md-font="--headline4"
                display='none'
                md-display='flex'
            >
                <Link
                    hover-opacity="1"
                    md-font='normal 500 18px/1.6 "Inter",sans-serif' 
                    font='normal 500 18px/1.6 "Inter",sans-serif' 
                    opacity="0.8"
                    transition="opacity 0.3s ease 0s"
                    href={isAuth?'/app':"/login"}
                    text-decoration-line="initial"
                    border-radius="20px"
                    padding='6px 8px'
                    background='#05a5ff45'
                    color='#ffffff'
                    white-space='nowrap'
                >
                    Open App
                </Link>
            </Box>
        </Box>
    </Section>
  )
}

export default Header
