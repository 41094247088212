import React from 'react'
import { Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Override } from "@quarkly/components";

import HeroImg from '../../assets/img/hero-img.png'


function Hero() {
  const isAuth = window.localStorage.getItem('token')?true:false

  return (
    <Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" height="90vh" md-height="80vh" quarkly-title="HeroBlock" md-padding="0px 0 60px 0">
        <Override
            slot="SectionContent"
            flex-direction="row"
            position="relative"
            z-index="9"
            md-flex-wrap="wrap"
            md-z-index="7"
        />
        <Box
            empty-min-width="64px"
            empty-min-height="64px"
            empty-border-width="1px"
            empty-border-style="solid"
            empty-border-color="LightGray"
            flex-direction="column"
            display="flex"
            align-items="flex-start"
            lg-padding="0px 90px 0px 0px"
            md-width="100%"
            justify-content="center"
            width="50%"
            padding="0px 0px 0px 0px"
            md-padding="0px 0px 0px 0px"
        >
            {/* <Text
                lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
                margin="0px 0px 8px 0px"
                color="--grey"
                lg-text-align="left"
                font="--base"
                text-transform="uppercase"
                lg-margin="0px 0px 8px 0px"
                sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
            >
                $11/mo. — no insurance needed
            </Text> */}
            <Text
                lg-text-align="left"
                lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
                md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
                margin="0px 0px 16px 0px"
                font="--headline1"
                color="--white"
                sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
            >
                Cutting-Edge, Secure Cryptocurrency Platform
            </Text>
            <Text
                font="--base"
                lg-text-align="left"
                lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
                md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
                margin="0px 0px 0px 0px"
                color="--white"
                opacity="1"
            >
                Select the ideal package and grow your funds now
            </Text>
            <Box
                lg-margin="32px 0px 0px 0px"
                md-width="100%"
                md-margin="36px 0px 0px 0px"
                display="flex"
                margin="72px 0px 0px 0px"
            >
                <Link
                    sm-margin="0px 22px 0px 0px"
                    transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
                    href={isAuth?'/app':"/create-account"}
                    text-decoration-line="initial"
                    sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                    md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                    sm-padding="15px 13px 15px 13px"
                    md-text-align="center"
                    lg-margin="0px 32px 0px 0px"
                    padding="15px 30px 15px 30px"
                    border-radius="10px"
                    lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                    md-padding="15px 16px 15px 16px"
                    hover-color="--primary"
                    color="--white"
                    margin="0px 44px 0px 0px"
                    lg-padding="15px 23px 15px 23px"
                    md-width="50%"
                    background="--color-primary"
                    font="--lead"
                    hover-background="--color-white"
                    border-width="2px"
                    border-style="solid"
                    border-color="--color-primary"
                >
                    Get Started
                </Link>
                <Link
                    padding="15px 30px 15px 30px"
                    font="--lead"
                    border-radius="10px"
                    border-width="2px"
                    transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
                    lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                    border-style="solid"
                    md-width="50%"
                    md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                    md-text-align="center"
                    sm-padding="15px 13px 15px 13px"
                    text-decoration-line="initial"
                    color="--white"
                    background="rgba(255, 5, 5, 0)"
                    margin="0px 0px 0px 0px"
                    border-color="rgba(255, 255, 255, 0.3)"
                    sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                    hover-background="--color-white"
                    hover-color="--indigo"
                    href="#about"
                    lg-padding="15px 23px 15px 23px"
                    md-padding="15px 16px 15px 16px"
                >
                    Learn more
                </Link>
            </Box>
        </Box>
        <Box
            empty-border-style="solid"
            width="50%"
            display="flex"
            justify-content="flex-end"
            empty-border-width="1px"
            empty-min-height="64px"
            empty-border-color="LightGray"
            align-items="center"
            md-width="100%"
            md-order="-1"
            empty-min-width="64px"
        >
            <Image
                lg-max-height="400px"
                width="100%"
        md-width="80%"
                max-height="450px"
                md-margin="auto"
                src={HeroImg}
                lg-position="relative"
                lg-left="-5px"
                max-width="100%"
            />
        </Box>
    </Section>
  )
}

export default Hero
