import React from 'react'
import { Box, Section, Text } from "@quarkly/widgets";
import { Override } from "@quarkly/components";

function Stats() {
  return (
    <Section id='stats' padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6123949e957019001e284458/images/Group%20177.png?v=2021-08-24T00:44:18.633Z) 0% 30% /cover no-repeat scroll padding-box" sm-padding="0px 0 0px 0" md-padding="50px 0 50px 0">
        <Override
            slot="SectionContent"
            display="grid"
            grid-gap="16px"
            grid-template-columns="repeat(4, 1fr)"
            lg-grid-gap="0px"
            md-grid-template-columns="repeat(2, 1fr)"
        />
        <Box
            justify-content="center"
            flex-direction="column"
            background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
            empty-min-width="64px"
            empty-border-width="1px"
            empty-border-color="LightGray"
            align-items="center"
            display="flex"
            min-height="320px"
            empty-min-height="64px"
            empty-border-style="solid"
            padding="0px 0px 60px 0px"
        >
            <Text
                lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
                lg-margin="0px 0px 8px 0px"
                position="relative"
                z-index="1"
                color="--indigo"
                font="normal 700 49px/1.2 --fontFamily-googleInter"
                margin="0px 0px 20px 0px"
            >
                350k+
            </Text>
            <Text
                text-align="center"
                opacity="0.7"
                lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
                md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                position="relative"
                z-index="1"
                color="--dark"
                font="--lead"
                margin="0px 0px 0px 0px"
            >
                Active Users
            </Text>
        </Box>
        <Box
            empty-min-width="64px"
            empty-border-style="solid"
            empty-border-color="LightGray"
            flex-direction="column"
            background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
            min-height="320px"
            padding="0px 0px 60px 0px"
            empty-min-height="64px"
            empty-border-width="1px"
            display="flex"
            align-items="center"
            justify-content="center"
        >
            <Text
                color="--indigo"
                font="normal 700 49px/1.2 --fontFamily-googleInter"
                margin="0px 0px 20px 0px"
                lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
                lg-margin="0px 0px 8px 0px"
                position="relative"
                z-index="1"
            >
                $45M+
            </Text>
            <Text
                md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
                z-index="1"
                font="--lead"
                margin="0px 0px 0px 0px"
                text-align="center"
                opacity="0.7"
                position="relative"
                color="--dark"
                lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
            >
                Investments
            </Text>
        </Box>
        <Box
            empty-min-height="64px"
            flex-direction="column"
            background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
            empty-border-style="solid"
            empty-border-color="LightGray"
            display="flex"
            sm-margin="0px 0px 0px 0px"
            empty-min-width="64px"
            empty-border-width="1px"
            justify-content="center"
            min-height="320px"
            md-margin="30px 0px 0px 0px"
            align-items="center"
            padding="0px 0px 60px 0px"
        >
            <Text
                margin="0px 0px 20px 0px"
                lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
                lg-margin="0px 0px 8px 0px"
                position="relative"
                z-index="1"
                color="--indigo"
                font="normal 700 49px/1.2 --fontFamily-googleInter"
            >
                24/7
            </Text>
            <Text
                opacity="0.7"
                md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
                z-index="1"
                margin="0px 0px 0px 0px"
                text-align="center"
                lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                position="relative"
                color="--dark"
                font="--lead"
            >
                Support{" "}
            </Text>
        </Box>
        <Box
            flex-direction="column"
            md-margin="30px 0px 0px 0px"
            empty-min-height="64px"
            empty-border-style="solid"
            empty-border-color="LightGray"
            empty-min-width="64px"
            display="flex"
            background="url(https://uploads.quarkly.io/6123949e957019001e284458/images/backtio.png?v=2021-08-24T00:48:34.217Z) center center/91% no-repeat"
            padding="0px 0px 60px 0px"
            sm-margin="0px 0px 0px 0px"
            min-height="320px"
            empty-border-width="1px"
            align-items="center"
            justify-content="center"
        >
            <Text
                position="relative"
                z-index="1"
                color="--indigo"
                font="normal 700 49px/1.2 --fontFamily-googleInter"
                margin="0px 0px 20px 0px"
                lg-font="normal 700 34px/1.2 --fontFamily-googleInter"
                lg-margin="0px 0px 8px 0px"
            >
                86
        </Text>
        <Text
            lg-width="70%"
            width="70%"
            position="relative"
            z-index="1"
            color="--dark"
            text-align="center"
            opacity="0.7"
            md-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
            font="--lead"
            margin="0px 0px 0px 0px"
            lg-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
            sm-font="normal 500 14px/1.6 &quot;Inter&quot;, sans-serif"
        >
            Available countries
        </Text>
    </Box>
</Section>
  )
}

export default Stats
