import React from 'react'
import { Image, Box, Section, Text, LinkBox } from "@quarkly/widgets";

import Feature1 from '../../assets/img/feature1.png'
import Feature2 from '../../assets/img/feature2.png'
import { referral_profit_percent } from '../../data';

function Features() {
  return (
    <Section id='features' lg-padding="60px 0 40px 0" padding="60px 0 100px 0" md-padding="30px 0 30px 0">
        <Text
            md-margin="0px 0px 40px 0px"
            lg-margin="0px 0px 40px 0px"
            margin="0px 0px 80px 0px"
            font="--headline4"
            color="--indigo"
            lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
            md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
            text-align="center"
        >
            Our Features
        </Text>
        
        <Box
            margin="0px 0px 20px 0px"
            sm-margin="0px 0px 30px 0px"
            border-radius="10px"
            min-height="284px"
            md-border-radius="0px"
            padding="0px 0px 0px 0px"
            border-style="solid"
            display="flex"
            border-width="2px"
            flex-wrap="wrap"
            md-border-width="0px 0px 2px 0px"
            width="100%"
            border-color="rgba(66, 82, 207, 0.1)"
            md-border-color="rgba(66, 82, 207, 0.1)"
        >
            <Box display="flex" lg-width="50%" md-width="100%" width="40%">
                <Image
                    margin="-1px 0px -1px -1px"
                    src={Feature1}
                    object-fit="cover"
                    width="100%"
                    border-radius="10px"
                    md-margin="0px 0px 0px 0px"
                />
            </Box>
            <Box
                padding="32px 17% 32px 62px"
                width="60%"
                justify-content="flex-start"
                md-width="100%"
                md-padding="32px 30px 32px 0px"
                display="flex"
                flex-direction="column"
                position="relative"
                lg-padding="32px 30px 32px 32px"
                lg-width="50%"
            >
                <Box
                    bottom="auto"
                    lg-right="16px"
                    lg-top="49px"
                    position="absolute"
                    right="32px"
                    top="32px"
                    left="auto"
                >
                </Box>
                <Text
                    color="--indigo"
                    width="85%"
                    lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
                margin="0px 0px 8px 0px"
                font="--headline4"
            >
                Convenient crypto wallet
            </Text>
            <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
                Our platform provides secure storage facilities and efficient transfers, as well as advanced security measures and ease of managing your finances for personal and business transactions.
            </Text>
        </Box>
        </Box>

        <Box
            display="flex"
            sm-margin="0px 0px 30px 0px"
            padding="0px 0px 0px 0px"
            md-border-width="0px 0px 2px 0px"
            margin="0px 0px 20px 0px"
            width="100%"
            border-style="solid"
            border-color="rgba(66, 82, 207, 0.1)"
            border-width="2px"
            border-radius="10px"
            flex-wrap="wrap"
            min-height="284px"
            md-border-radius="0px"
        >
            <Box
                padding="32px 17% 32px 62px"
                width="60%"
                display="flex"
                position="relative"
                md-padding="32px 30px 32px 0px"
                flex-direction="column"
                justify-content="flex-start"
                lg-padding="32px 30px 32px 32px"
                lg-width="50%"
                md-width="100%"
            >
                <Text
                    margin="0px 0px 8px 0px"
                    font="--headline4"
                    color="--indigo"
                    width="85%"
                    lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
                >
                    Profitable affiliate program
                </Text>
                <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
                    Invite your friends to participate in the project and receive income of up to {referral_profit_percent}% of the amount of their investments, thereby strengthening your financial network and contributing to overall growth.
                </Text>
            </Box>
            <Box
                md-order="-1"
                width="40%"
                display="flex"
                lg-width="50%"
                md-width="100%"
            >
                <Image
                    object-fit="cover"
                    width="100%"
                    border-radius="10px"
                    md-margin="0px 0px 0px 0px"
                    margin="-1px -1px -1px 0px"
                    src={Feature2}
                />
            </Box>
        </Box>

    </Section>
  )
}

export default Features
