import React from 'react'
import { Box, Section, Text, Icon, Link } from "@quarkly/widgets";
import { FaChartLine } from "react-icons/fa";
import { Override } from "@quarkly/components";
import { tariffs } from '../../data';
import { to_usd_amount } from '../../utils/utils';

function Companies() {
  return (
    <Section id='earn' md-padding="60px 0 60px 0" padding="84px 0 60px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
        <Box
            border-radius="10px"
            flex-direction="column"
            width="100%"
            md-padding="0px 0px 0px 0px"
            sm-padding="16px 16px 16px 16px"
            display="flex"
            align-items="center"
            justify-content="center"
            margin="0px 0px 0px 0px"
            lg-margin="0px 0px 0px 0px"
            md-width="100%"
            sm-width="100%"
            padding="16px 16px 16px 16px"
            lg-width="50%"
            text-align='center'
        >
            <Text
                lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
                position="relative"
                z-index="1"
                color="--indigo"
                font="--headline4"
                margin="15px 0px 0px 0px"
                sm-font="700 25px/1.3 &quot;Inter&quot;, sans-serif"
            >
                Discover Your Potential Earnings
            </Text>
            <Text margin="20px 0px 10px 0px" color="--dark" font="--base" opacity=".9">
                Start investing right now by selecting suitable package
            </Text>
        </Box>

        {
            tariffs.map(camp => (
                <Box
                    empty-min-height="64px"
                    empty-border-width="1px"
                    empty-border-color="LightGray"
                    empty-min-width="64px"
                    empty-border-style="solid"
                    padding="16px 16px 16px 16px"
                    width="33.333%"
                    md-width="100%"
                    sm-width="100%"
                    lg-width="50%"
                >
                    <Box
                        border-color="rgba(66, 82, 207, 0.1)"
                        lg-height="100%"
                        overflow-x="hidden"
                        overflow-y="hidden"
                        height="100%"
                        width="100%"
                        flex-direction="column"
                        border-style="solid"
                        border-width="2px"
                        border-radius="10px"
                        padding="32px 32px 32px 32px"
                        display="flex"
                        justify-content="flex-end"
                        md-min-height="200px"
                        lg-min-height="350px"
                        align-items="center"
                    >
                        <Icon
                            font="48px sans-serif"
                            category="fa"
                            icon={FaChartLine}
                            size="48px"
                            color="--indigo"
                        />
                        <Text color="--indigo" font="--lead" margin="8px 0px 8px 0px">
                            {camp.name}
                        </Text>

                        <Box display='flex' width='100%' justify-content='space-between'>
                            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
                                Profit per day
                            </Text>
                            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
                                ~{camp.profit}%
                            </Text>
                        </Box>
                        <Box display='flex' width='100%' justify-content='space-between'>
                            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
                                Working days
                            </Text>
                            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
                                {camp.days}
                            </Text>
                        </Box>
                        <Box display='flex' width='100%' justify-content='space-between'>
                            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
                                Min deposit
                            </Text>
                            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
                                {to_usd_amount(camp.min_deposit)}
                            </Text>
                        </Box>
                        <Box display='flex' width='100%' justify-content='space-between'>
                            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
                                Max deposit
                            </Text>
                            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
                                {to_usd_amount(camp.max_deposit)}
                            </Text>
                        </Box>

                        <Link
                            transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
                            href="/create-account"
                            text-decoration-line="initial"
                            font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                            sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                            md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
                            lg-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
                            
                            md-text-align="center"
                            border-radius="10px"
                            hover-color="--primary"
                            color="--white"

                            margin="20px 0px 0px 0px"
                            sm-margin="20px 0px 0px 0px"
                            lg-margin="20px 0px 0px 0px"

                            padding="5px 20px 5px 20px"
                            md-padding="5px 16px 5px 16px"
                            sm-padding="5px 13px 5px 13px"
                            lg-padding="5px 23px 5px 23px"

                            md-width="50%"
                            background="--color-primary"
                            hover-background="--color-white"
                            border-width="2px"
                            border-style="solid"
                            border-color="--color-primary"
                        >
                            Invest
                        </Link>
                    </Box>
                </Box>
            ))
        }
    </Section>
  )
}

export default Companies
