import React from 'react'
import { Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Override, SocialMedia } from "@quarkly/components";

import Logo from '../../assets/img/logo.png'
import { project_name } from '../../data';

function Footer() {
  return (
    <Section background="--color-indigo" padding="45px 0 45px 0" md-padding="40px 0 40px 0">
        <Override slot="SectionContent" flex-direction="column" align-items='center' md-flex-wrap="wrap" />
        <Box
            empty-border-color="LightGray"
            align-items='center'
            flex-direction="column"
            md-justify-content="center"
            display="flex"
            padding="16px 16px 16px 16px"
            md-width="100%"
            empty-min-width="64px"
            empty-min-height="64px"
            empty-border-width="1px"
            empty-border-style="solid"
            width="25%"
            md-align-items="center"
        >
            <Image src={Logo} margin="0px 0px 0px 0px" max-width="171px" />
            <SocialMedia
                facebook=""
                twitter=""
                telegram=""
                instagram=""
                align-items="flex-start"
                justify-content="flex-start"
            >
                <Override
                    slot="link"
                    margin="0 12px 0 0px"
                    hover-background="--color-indigo"
                    background="--color-greyD1"
                    lg-height="28px"
                    lg-padding="4px 4px 4px 4px"
                    lg-display="flex"
                    lg-align-items="center"
                    border-radius="50%"
                    lg-justify-content="center"
                    lg-width="28px"
                    color="--light"
                />
                <Override slot="icon" lg-font="18px sans-serif" />
            </SocialMedia>
        </Box>
        
        <Box
            empty-min-width="64px"
            empty-min-height="64px"
            empty-border-color="LightGray"
            width="auto"
            display="flex"
            md-width="100%"
            md-justify-content="center"
            empty-border-width="1px"
            empty-border-style="solid"
            flex-direction="column"
            padding="16px 16px 16px 16px"
            md-align-items="center"
        >
            <Text color="--white" font="--base" margin="0px 0px 0px 0px">
                © 2024 {project_name}. All rights reserved.
            </Text>
        </Box>
    </Section>
  )
}

export default Footer
