import React from 'react'
import { Image, Box, Section, Text, Hr } from "@quarkly/widgets";
import { Override } from "@quarkly/components";

import binance from '../../assets/img/binance.png'
import coinbase from '../../assets/img/coinbase.png'
import bancor from '../../assets/img/bancor.png'
import foxbit from '../../assets/img/foxbit.png'
import nanex from '../../assets/img/nanex.png'
import yobit from '../../assets/img/yobit.png'

function Partners() {
  return (
    <Section
        padding="60px 0 90px 0"
        position="relative"
        lg-padding="60px 0 60px 0"
        sm-padding="30px 0 30px 0"
        md-padding="30px 0 30px 0"
    >
        <Override
            slot="SectionContent"
            lg-padding="0px 0px 0px 0px"
            max-width="1194px"
            flex-wrap="wrap"
            flex-direction="row"
        />
        <Box
            lg-align-items="flex-start"
            flex-direction="row"
            justify-content="flex-start"
            margin="0px 0px 64px 0px"
            padding="16px 0px 16px 0px"
            lg-margin="0px 0px 24px 0px"
            sm-flex-wrap="wrap"
            display="flex"
            align-items="center"
            sm-margin="0px 0px 30px 0px"
            width="100%"
        >
            <Box width="100%" text-align='center' sm-width="100%">
                <Text color="--indigo" sm-margin="0px 0px 16px 0px" margin="0px 0px 0px 0px" font="--headline4">
                    Our Partners
                </Text>
            </Box>
        </Box>
        <Hr
            border-style="solid"
            height="0px"
            margin="0px 0px 32px 0px"
            lg-margin="0px 0px 16px 0px"
            lg-display="none"
            width="100%"
            border-color="rgba(66, 82, 207, 0.1)"
            border-width="2px 0 0 0"
        />
        <Box
            margin="0px 0px 0px 0px"
            sm-flex-wrap="wrap"
            sm-margin="0px 0px 0px 0px"
            justify-content="center"
            flex-wrap="wrap"
            width="100%"
            display="flex"
        >
            <Box
                border-style="solid"
                sm-padding="0px 0px 0px 0px"
                border-color="rgba(66, 82, 207, 0.1)"
                lg-padding="16px 16px 16px 16px"
                md-width="50%"
                sm-display="flex"
                sm-align-items="center"
                width="33.333%"
                padding="32px 74px 32px 74px"
                border-width="0px 1px 0px 0px"
                sm-justify-content="center"
            >
                <Box
                    box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
                    justify-content="center"
                    margin="0px 0px 0px 0px"
                    sm-width="90%"
                    width="100%"
                    padding="18px 25px 18px 25px"
                    display="flex"
                    align-items="center"
                    lg-padding="18px 18px 18px 18px"
                >
                    <Image src={binance} />
                </Box>
            </Box>
            <Box
                border-width="0px 1px 0px 1px"
                md-border-width="0px 0px 0px 1px"
                sm-display="flex"
                sm-align-items="center"
                md-width="50%"
                sm-padding="0px 0px 0px 0px"
                sm-justify-content="center"
                width="33.333%"
                padding="32px 74px 32px 74px"
                border-style="solid"
                border-color="rgba(66, 82, 207, 0.1)"
                lg-padding="16px 16px 16px 16px"
            >
                <Box
                    sm-width="90%"
                    width="100%"
                    padding="18px 25px 18px 25px"
                    align-items="center"
                    margin="0px 0px 0px 0px"
                    box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
                    display="flex"
                    justify-content="center"
                    lg-padding="18px 18px 18px 18px"
                >
                    <Image src={coinbase} />
                </Box>
            </Box>
            <Hr
                md-display="block"
                display="none"
                border-style="solid"
                height="0px"
                lg-margin="16px 0px 16px 0px"
                margin="32px 0px 32px 0px"
                width="100%"
                border-color="rgba(66, 82, 207, 0.1)"
                border-width="2px 0 0 0"
            />
            <Box
                lg-padding="16px 16px 16px 16px"
                md-width="50%"
                md-border-width="0px 1px 0px 0px"
                sm-display="flex"
                sm-justify-content="center"
                padding="32px 74px 32px 74px"
                border-width="0px 0px 0px 1px"
                border-style="solid"
                sm-align-items="center"
                width="33.333%"
                border-color="rgba(66, 82, 207, 0.1)"
                sm-padding="0px 0px 0px 0px"
            >
                <Box
                    margin="0px 0px 0px 0px"
                    width="100%"
                    box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
                    align-items="center"
                    lg-padding="18px 18px 18px 18px"
                    sm-width="90%"
                    padding="18px 25px 18px 25px"
                    display="flex"
                    justify-content="center"
                >
                    <Image src={bancor} />
                </Box>
            </Box>
            <Hr
                margin="32px 0px 32px 0px"
                lg-margin="16px 0px 16px 0px"
                md-display="none"
                width="100%"
                border-color="rgba(66, 82, 207, 0.1)"
                border-width="2px 0 0 0"
                border-style="solid"
                height="0px"
            />
            <Box
                padding="32px 74px 32px 74px"
                border-style="solid"
                md-border-width="0px 0px 0px 1px"
                sm-display="flex"
                sm-justify-content="center"
                width="33.333%"
                border-width="0px 1px 0px 0px"
                border-color="rgba(66, 82, 207, 0.1)"
                lg-padding="16px 16px 16px 16px"
                md-width="50%"
                sm-padding="0px 0px 0px 0px"
                sm-align-items="center"
            >
                <Box
                    padding="18px 25px 18px 25px"
                    display="flex"
                    align-items="center"
                    lg-padding="18px 18px 18px 18px"
                    width="100%"
                    box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
                    justify-content="center"
                    margin="0px 0px 0px 0px"
                    sm-width="90%"
                >
                    <Image src={foxbit} />
                </Box>
            </Box>
            <Hr
                height="0px"
                border-width="2px 0 0 0"
                border-style="solid"
                margin="32px 0px 32px 0px"
                lg-margin="16px 0px 16px 0px"
                md-display="block"
                display="none"
                width="100%"
                border-color="rgba(66, 82, 207, 0.1)"
            />
            <Box
                md-width="50%"
                sm-padding="0px 0px 0px 0px"
                sm-justify-content="center"
                padding="32px 74px 32px 74px"
                border-style="solid"
                border-color="rgba(66, 82, 207, 0.1)"
                sm-display="flex"
                sm-align-items="center"
                md-border-width="0px 1px 0px 0px"
                width="33.333%"
                border-width="0px 1px 0px 1px"
                lg-padding="16px 16px 16px 16px"
            >
                <Box
                    width="100%"
                    padding="18px 25px 18px 25px"
                    display="flex"
                    justify-content="center"
                    margin="0px 0px 0px 0px"
                    lg-padding="18px 18px 18px 18px"
                    sm-width="90%"
                    box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
                    align-items="center"
                >
                    <Image src={nanex} />
                </Box>
            </Box>
            <Box
                sm-display="flex"
                sm-align-items="center"
                width="33.333%"
                border-color="rgba(66, 82, 207, 0.1)"
                lg-padding="16px 16px 16px 16px"
                sm-padding="0px 0px 0px 0px"
                sm-justify-content="center"
                padding="32px 74px 32px 74px"
                border-width="0px 0px 0px 1px"
                border-style="solid"
                md-width="50%"
            >
                <Box
                    justify-content="center"
                    margin="0px 0px 0px 0px"
                    lg-padding="18px 18px 18px 18px"
                    width="100%"
                    box-shadow="1px 4px 12px rgba(0, 0, 0, 0.08)"
                    padding="18px 25px 18px 25px"
                    display="flex"
                    align-items="center"
                    sm-width="90%"
                >
                    <Image src={yobit} />
                </Box>
            </Box>
        </Box>
        <Hr
            border-style="solid"
            height="0px"
            margin="32px 0px 0px 0px"
            lg-margin="16px 0px 0px 0px"
            lg-display="none"
            width="100%"
            border-color="rgba(66, 82, 207, 0.1)"
            border-width="2px 0 0 0"
        />
    </Section>
  )
}

export default Partners
