// import images
import BitconImg from './assets/img/bitcon.png';
import EthereumImg from './assets/img/ethereum.png';
import LitecoinImg from './assets/img/litecoin.png';

import binance from './assets/img/binance.png';
import coinbase from './assets/img/coinbase.png';
import yobit from './assets/img/yobit.png';
import nanex from './assets/img/nanex.png';
import foxbit from './assets/img/foxbit.png';


export const project_name = 'AI-Trade'
export const domain = 'https://ai-trade.online/'
export const back_endpoint = process.env.NODE_ENV === 'development'?'http://192.168.0.108:8000/api/v1':'https://api.ai-trade.online/api/v1'
export const referral_profit_percent = 10;
export const RECAPTCHA_PUBLIC_KEY = '6Ld-GwkqAAAAAPSoMV9we99URnzD_EW-RE838CN1'


export const navData = [
  {
    name: 'Products',
    link: '#',
  },
  {
    name: 'Features',
    link: '#',
  },
  {
    name: 'About',
    link: '#',
  },
  {
    name: 'Contact',
    link: '#',
  },
];

export const tariffs = [
  {
    name: 'Classic',
    days: 14,
    min_deposit: 100,
    max_deposit: 2900,
    profit: 50,
    color: 'rgb(129 101 0)'
  },
  {
    name: 'Premium',
    days: 28,
    min_deposit: 1500,
    max_deposit: 55000,
    profit: 30,
    color: 'rgb(58 106 0)'
  },
  {
    name: 'Deluxe',
    days: 55,
    min_deposit: 5000,
    max_deposit: 280000,
    profit: 10,
    color: 'rgb(124 0 29)'
  },
];

export const currency = [
  {
    image: BitconImg,
    name: 'Bitcoin',
    abbr: 'BTC',
    description:
      'Digital currency in which a record of transactions is maintained.',
  },
  {
    image: EthereumImg,
    name: 'Ethereum',
    abbr: 'ETH',
    description:
      'Blockchain technology to create and run decentralized digital applications.',
  },
  {
    image: LitecoinImg,
    name: 'Litecoin',
    abbr: 'LTC',
    description:
      'Cryptocurrency that enables instant payments to anyone in the world.',
  },
];

export const partners = [
  {
    name: 'binance',
    image: binance,
  },
  {
    name: 'coinbase',
    image: coinbase,
  },
  {
    name: 'yobit',
    image: yobit,
  },
  {
    name: 'nanex',
    image: nanex,
  },
  {
    name: 'foxbit',
    image: foxbit,
  }
]


