import React from "react";
import { useLocation } from "react-router-dom";

import theme from "../theme";
import { Theme } from "@quarkly/widgets";
import { GlobalQuarklyPageStyles } from "../langing-page-styles";

import Hero from "../components/Land/Hero";
import Header from "../components/Land/Header";
import About from "../components/Land/About";
import Stats from "../components/Land/Stats";
import { useTitle } from "react-use";
import { project_name } from "../data";
import Companies from "../components/Land/Companys";
import Reviews from "../components/Land/Reviews";
import Features from "../components/Land/Features";
import Partners from "../components/Land/Partners";
import Footer from "../components/Land/Footer";

export default (() => {
  useTitle(project_name + ' - crypto investment platform')

  const search = useLocation().search;
  const ref = new URLSearchParams(search).get("ref")
  if (ref){
    window.localStorage.setItem('ref', ref)
  }

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />

    <Header/>
		<Hero/>
    <About/>
    <Stats/>
    <Companies/>
    <Reviews/>
    <Features/>
    <Partners/>
    <Footer/>

	</Theme>;
});